import React from "react";
import { Link } from "gatsby";

import IndivSpeaker from "./IndivSpeaker";

const Speakers = ({ presenters }) => {
  const speakersList = presenters
    .filter(p => !p.frontmatter.presenter_slug.includes("placeholder"))
    .sort((p1, p2) => p1.frontmatter.position - p2.frontmatter.position)
    .map((presenter, index) => {
      if (index < 6) {
        return (
          <IndivSpeaker
            key={index}
            speaker={presenter}
            entry={index}
            base="/events/hasura-con-2021/speakers"
          />
        );
      }

      return null;
    });

  return (
    <div id="speakers" className="speakersWrapper sectionWrapper borderBottom patternWrapper">
      <div className="speakersHeader mb-large">
        <div className="articleTitle">We’ve got some stellar speakers in the line up :)</div>
      </div>
      <div className="speakersWrapperOverflow">
        <div className="speakersListWrapper">{speakersList}</div>
      </div>
      <div className="buttonWrapper">
        <Link
          className="hasuraConCommonAction hasuraConGreenBtn"
          to="/events/hasura-con-2021/speakers"
        >
          View all speakers &rarr;
        </Link>
      </div>
    </div>
  );
};

export default Speakers;
