import React from "react";
import "../styles.scss";
import diversity from "../images/diversity.png";
const WelcomeHasuraCon = props => {
  return (
    <section className="sectionWrapper">
      <div className="welcomeHasuraConWrapper">
        <div className="alignSelfCenter">
          <div className="articleTitle">
            Our commitment is a diverse and welcoming HasuraCon for everyone, always.
          </div>
          <div className="articleDesc">
            We are committed to speakers and participants from underrepresented groups in
            technology. For more information, or if you have any questions, reach us at{" "}
            <a href="mailto:community@hasura.io">community@hasura.io</a>
          </div>
        </div>
        <div className="welcomeHasuraConImg">
          <img src={diversity} alt="diversity" />
        </div>
      </div>
    </section>
  );
};

export default WelcomeHasuraCon;
