import React from "react";

import PresentationListItem from "./PresentationListItem";
import "../styles.scss";

const Workshops = ({ presentations }) => {
  const talkList = presentations
    .filter(p => p.node.relativeDirectory === "workshops")
    .sort((p1, p2) => {
      return p1.node.childMdx.frontmatter.position - p2.node.childMdx.frontmatter.position;
    })
    .map((presentation, index) => {
      return (
        <PresentationListItem
          key={index}
          presentation={presentation}
          base="/events/hasura-con-2021/talks"
        />
      );
    });

  return (
    <div id="speakers" className="speakersWrapper sectionWrapper borderBottom patternWrapper">
      <div className="speakersHeader mb-large">
        <div className="articleTitle">Check out the workshops</div>
      </div>
      <div className="presentationListWrapper">{talkList}</div>
    </div>
  );
};

export default Workshops;
