import React from "react";
import { Link } from "gatsby";

import cfp from "../images/cfp.png";
import "../styles.scss";

const InterestedSpeakers = () => {
  return (
    <section className="sectionWrapper borderBottom patternWrapper">
      <div className="interestedSpeakersWrapper">
        <div>
          <div className="articleTitle">Why You Should Attend HasuraCon</div>
          <div className="articleDesc">
            HasuraCon is a conference about you, your success, and...of course…full of deep-dive
            technical content. We are bringing together users from all over the world for a 2 day
            celebration of all things Hasura!
            <br />
            <br />
            But who is it for? And why should you attend?
          </div>
          <Link to="/events/hasura-con-2021/why-attend/">
            <div className="buttonWrapper">
              <button className="hasuraConCommonAction hasuraConGreenBtn">Read more →</button>
            </div>
          </Link>
        </div>
        <div className="interestedSpeakersIcon">
          <img loading="lazy" src={cfp} alt="CFPs" />
        </div>
      </div>
    </section>
  );
};

export default InterestedSpeakers;
