import React from "react";
import { Link } from "gatsby";
import "../styles.scss";
import PresentationListItem from "./PresentationListItem";
const Talks = ({ presentations }) => {
  const talkList = presentations
    .filter(p => p.node.relativeDirectory === "talks")
    .filter(p => !p.node.name.includes("placeholder"))
    .sort((p1, p2) => {
      return p1.node.childMdx.frontmatter.position - p2.node.childMdx.frontmatter.position;
    })
    .map((presentation, index) => {
      if (index < 4) {
        return (
          <PresentationListItem
            key={index}
            presentation={presentation}
            base="/events/hasura-con-2021/talks"
          />
        );
      }

      return null;
    });

  return (
    <div id="speakers" className="speakersWrapper sectionWrapper borderBottom patternWrapper">
      <div className="speakersHeader mb-large">
        <div className="articleTitle">Check out the talks</div>
      </div>
      <div className="presentationListWrapper">{talkList}</div>
      <div className="buttonWrapper">
        <Link
          className="hasuraConCommonAction hasuraConGreenBtn"
          to="/events/hasura-con-2021/talks"
        >
          View all talks &rarr;
        </Link>
      </div>
    </div>
  );
};

export default Talks;
