import React from "react";

import yugabyteImg from "../images/yugabyte-dark.png";
import timescaleImg from "../images/timescale_logo.svg";
import "../styles.scss";

const Sponsor = () => {
  return (
    <section id="sponsors" className="sectionWrapper borderBottom">
      <div className="sponsorWrapper">
        <div>
          <div className="articleTitle">Sponsors</div>
          <div className="sponsors-wrapper-div">
            <a
              href="https://www.yugabyte.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={yugabyteImg} id="yugabyte-img" alt="Yugabyte" />
            </a>
            <a href="https://www.timescale.com/" target="_blank" rel="noopener noreferrer">
              <img src={timescaleImg} id="timescale-img" alt="Timescale" />
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Sponsor;
